<template>
  <modal>
    <modal-header :title="title"></modal-header>
    <modal-body>
      <form-field :label="$t('attributes.title')" :error="v$.title.$error">
        <input v-model="model.title" title-input type="text" :placeholder="$t('attributes.title') + '...'" />
      </form-field>
      <div class="mt-6 flex gap-6">
        <div>
          <form-field :label="$t('attributes.thumbnail')">
            <div
              v-lazy:background-image="previewImage"
              class="w-16 h-16 border rounded-xs flex items-center justify-center bg-cover"
              :class="v$.preview_image.$error ? 'border-error' : 'border-border-primary'"
              @click="addImage"
            >
              <div v-if="!model.preview_image" class="text-button-small text-text-variant">
                {{ $t("actions.upload") }}
              </div>
            </div>
          </form-field>
        </div>
        <div class="flex-1">
          <form-field :label="$t('attributes.speciality')">
            <speciality-select v-model="model.speciality_id" :error="v$.speciality_id.$error"></speciality-select>
          </form-field>
        </div>
      </div>
    </modal-body>
    <modal-footer>
      <button type="button" class="btn-secondary" @click="cancel">{{ $t("actions.discard") }}</button>
      <button type="button" class="btn-primary" @click="submit">
        {{ release ? $t("actions.save") : $t("actions.add") }}
      </button>
    </modal-footer>
  </modal>
</template>

<script setup lang="ts">
import { useCreateUpcomingRelease, useUpdateUpcomingRelease } from "@/api/upcoming-releases";
import LoadingIndicatorDialog from "@/components/loading-indicator-dialog.vue";
import MediaLibraryModal from "@/components/media-library/media-library-modal.vue";
import MediaFile from "@/model/media-file";
import UpcomingRelease from "@/model/upcoming-release";
import { showDialog } from "@/utils/dialogs";
import { filePath } from "@/utils/paths";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { computed, reactive } from "vue";
import { useI18n } from "vue-i18n-bridge";

const props = defineProps<{ release?: UpcomingRelease }>();

const emit = defineEmits(["cancel", "ok"]);

const { t } = useI18n();

const title = computed(() => {
  return props.release ? t("upcoming_releases.edit_upcoming_release") : t("upcoming_releases.add_upcoming_release");
});

const model = reactive({
  title: props.release?.title ?? "",
  speciality_id: props.release?.speciality_id ?? null,
  preview_image: props.release?.preview_image ?? null,
});

const validationRules = {
  title: { required },
  speciality_id: { required },
  preview_image: { required },
};

const v$ = useVuelidate(validationRules, model);

const previewImage = computed(() => filePath(model.preview_image, "thumb"));

const { mutateAsync: createUpcomingRelease } = useCreateUpcomingRelease();

const { mutateAsync: updateUpcomingRelease } = useUpdateUpcomingRelease(props.release?.id);

const addImage = async () => {
  const mediaFiles = await showDialog<MediaFile[]>(MediaLibraryModal, {
    fileType: "image",
    allowMultiselect: false,
  });
  if (mediaFiles && mediaFiles.length > 0) {
    model.preview_image = mediaFiles[0].file;
  }
};

const cancel = () => {
  emit("cancel");
};

const submit = async () => {
  const isValid = await v$.value.$validate();
  if (!isValid) {
    return;
  }
  const action = props.release ? updateUpcomingRelease(model) : createUpcomingRelease(model);
  await showDialog(LoadingIndicatorDialog, { action });
  emit("ok");
};
</script>
