<template>
  <component
    :is="linkComponent"
    class="shrink-0 flex flex-col rounded duration-300 group sm:w-[calc(33.33%-0.66rem)] sm:flex-row sm:h-[84px] sm:bg-surface-low-contrast hover:bg-surface-mid-contrast hover:shadow-sm active:bg-surface-low-contrast"
    v-bind="linkAttributes"
  >
    <div
      class="shrink-0 bg-interactive-bg-emphasis rounded overflow-hidden aspect-square duration-300 sm:rounded-r-none sm:h-full group-active:shadow-sm group-active:sm:shadow-none"
    >
      <div
        v-lazy:background-image="previewImage"
        class="bg-cover opacity-0 duration-300 ease-in h-full w-full lazy-loaded:opacity-100"
      ></div>
    </div>
    <div class="flex flex-col p-2">
      <div class="text-body-small text-text-emphasis line-clamp-1 leading-4">
        {{ relativeDateFilter(contentItem.latest_content_item_release.created_at) }}
      </div>
      <div class="text-body-small text-text-primary line-clamp-2">{{ contentItem.name }}</div>
      <div class="flex-1"></div>
      <div v-if="speciality" class="text-body-small text-text-variant hidden leading-4 xs:line-clamp-1">
        {{ speciality.name }}
      </div>
    </div>
  </component>
</template>

<script lang="ts">
import ContentItem from "@/model/content-item";
import { filePath } from "@/utils/paths";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { routeForContentItem } from "@/utils/routes";
import { relativeDateFilter } from "@/filters";

@Component({})
export default class ContentItemCard extends Vue {
  @Prop()
  contentItem: ContentItem;

  get previewImage() {
    return filePath(this.contentItem.preview_image, "thumb");
  }

  get speciality() {
    return this.contentItem.specialities?.[0];
  }

  get route() {
    if (this.contentItem.id) {
      return routeForContentItem(this.contentItem);
    } else {
      return "/";
    }
  }

  get linkComponent() {
    if (this.contentItem.content_type == "ExternalLink") {
      return "a";
    } else {
      return "router-link";
    }
  }

  get linkAttributes() {
    if (this.contentItem.content_type == "ExternalLink") {
      return {
        href: `/external_links/${this.contentItem.content_id}`,
        target: "_blank",
        rel: "noopener noreferrer",
      };
    } else {
      return {
        to: this.route,
      };
    }
  }

  relativeDateFilter(value: string) {
    return relativeDateFilter(value);
  }
}
</script>
