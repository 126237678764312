import UpcomingRelease from "@/model/upcoming-release";
import { useApi, useApiMutation } from "./utils";

export const useUpcomingReleases = () => {
  return useApi<UpcomingRelease[]>(["upcomingReleases"], `/api/v1/upcoming_releases`);
};

export const useCreateUpcomingRelease = () => {
  return useApiMutation<Omit<UpcomingRelease, "id">>(`/api/v1/upcoming_releases`, {
    invalidateQueries: [["upcomingReleases"]],
  });
};

export const useUpdateUpcomingRelease = (id) => {
  return useApiMutation<Omit<UpcomingRelease, "id">>(`/api/v1/upcoming_releases/${id}`, {
    method: "PUT",
    invalidateQueries: [["upcomingReleases"]],
  });
};

export const useDeleteUpcomingRelease = (id) => {
  return useApiMutation(`/api/v1/upcoming_releases/${id}`, {
    method: "DELETE",
    invalidateQueries: [["upcomingReleases"]],
  });
};
