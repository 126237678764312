<template>
  <div v-if="plan">
    <loading-indicator-view v-if="sending" :title="$t('signup.add_payment_method.adding_payment_method')" />

    <sign-up-view
      v-show="!sending"
      :step="3"
      :back="false"
      :title="$t('signup.add_payment_method.title')"
      :subtitle="$t('signup.add_payment_method.subtitle')"
      v-on="$listeners"
    >
      <div class="w-full sm:w-[520px]">
        <selected-plan-row :plan="plan" :sign-up-code="signUpCode" />
        <div class="mt-12 sm:px-6 flex flex-col gap-4">
          <payment-method-card
            :title="$t('signup.add_payment_method.card.title')"
            :open="paymentMethod == 'card'"
            @click="togglePaymentMethod('card')"
          >
            <div class="flex gap-1">
              <credit-card-image brand="visa" />
              <credit-card-image brand="amex" />
              <credit-card-image brand="mastercard" />
              <credit-card-image brand="discover" />
            </div>
            <credit-card-form ref="creditCardForm" class="mt-4" @valid="isCardValid = $event" />
            <div class="mt-8 flex gap-4">
              <div class="basis-3/4">
                <text-input
                  v-model="store.sign_up_code"
                  type="text"
                  :success="signUpCode"
                  :error="signUpCodeInvalid"
                  :validation-message="signUpCodeValidationMessage"
                  :placeholder="t('signup.enter_promo_code')"
                  :floating-label="true"
                />
              </div>
            </div>
            <div class="my-12 px-2 py-4 border-y border-border-primary flex flex-col gap-3 text-text-variant text-left">
              <div>{{ $t("signup.add_payment_method.subscription_auto_renew_info") }}</div>
              <div>{{ $t("plan.cancel_membership_info_text") }}</div>
              <div>{{ $t("signup.add_payment_method.choose_create_account_info") }}</div>
            </div>
            <button class="btn-primary" :disabled="sending || !isCardValid || signUpCodeInvalid" @click="confirmAndPay">
              {{ $t("actions.start_membership") }}
            </button>
          </payment-method-card>
          <payment-method-card
            :open="paymentMethod == 'paypal_express_checkout'"
            @click="togglePaymentMethod('paypal_express_checkout')"
          >
            <template #title><img :src="require('@/assets/images/paypal.png')" /></template>
            <div class="mt-6 flex gap-4">
              <div class="basis-3/4">
                <text-input
                  v-model="store.sign_up_code"
                  type="text"
                  :success="signUpCode"
                  :error="signUpCodeInvalid"
                  :validation-message="signUpCodeValidationMessage"
                  :placeholder="t('signup.enter_promo_code')"
                  :floating-label="true"
                />
              </div>
            </div>
            <div
              class="mt-6 mb-8 px-2 py-4 border-y border-border-primary flex flex-col gap-3 text-text-variant text-left"
            >
              <div>{{ $t("signup.add_payment_method.subscription_auto_renew_info") }}</div>
              <div>{{ $t("plan.cancel_membership_info_text") }}</div>
              <div>{{ $t("signup.add_payment_method.check_out_with_paypal_info") }}</div>
            </div>
            <paypal-button :disabled="signUpCodeInvalid" @success="onPayPalSuccess" @error="onPaypalError" />
          </payment-method-card>
        </div>
      </div>
    </sign-up-view>
  </div>
</template>

<script setup lang="ts">
import Vue, { computed, ref } from "vue";
import SignUpView from "./sign-up-view.vue";
import SelectedPlanRow from "./selected-plan-row.vue";
import { useI18n } from "vue-i18n-bridge";
import { PaymentMethodType, useSignUpStore } from "@/pages/sign-up/signup-store";
import PaymentIntentsService from "@/services/payment-intents-service";
import LoadingIndicatorView from "@/pages/loading-indicator-view.vue";
import CreditCardForm from "@/components/credit-card/credit-card-form.vue";
import { useRouter } from "@/composables/router";
import PaymentMethodCard from "./payment-method-card.vue";
import PaypalButton from "./paypal-button.vue";
import SubscriptionsService from "@/services/subscriptions-service";
import { usePlanPrices } from "@/api/plan-prices";
import { storeToRefs } from "pinia";
import { useSignUpCode } from "@/api/sign-up-codes";
import { useRollbar } from "@/composables/rollbar";
import { PaymentRequired } from "@/api/api-error";

const { t } = useI18n();

const router = useRouter();

const rollbar = useRollbar();

const creditCardForm = ref<typeof CreditCardForm>(null);

const paymentMethod = ref<PaymentMethodType>(null);

const sending = ref(false);

const isCardValid = ref(false);

const store = useSignUpStore();

const storeRefs = storeToRefs(store);

const { data: plans } = usePlanPrices();

const plan = computed(() => plans.value?.find(({ period }) => period === store.period));

const { data: signUpCode } = useSignUpCode(storeRefs.sign_up_code);

const signUpCodeInvalid = computed(() => !!store.sign_up_code && store.sign_up_code.length > 0 && !signUpCode.value);

const signUpCodeValidationMessage = computed(() => {
  if (signUpCode.value) {
    return t("signup.valid_code");
  } else if (signUpCodeInvalid.value) {
    return t("errors.sign_up_code_not_valid");
  } else {
    return null;
  }
});

const confirmAndPay = async () => {
  if (sending.value) {
    return;
  }
  try {
    sending.value = true;
    if (signUpCodeInvalid.value) {
      return;
    }
    if (paymentMethod.value === "card") {
      await authorizeWith3ds();
    }
    await createSubscription();
    router.push({ name: "SignupWelcome" });
  } catch (error) {
    if (error instanceof PaymentRequired) {
      const message = error.message;
      Vue.$toast.error(message, { message, position: "top-right" });
    } else {
      const message = t("payment.adding_payment_method_failed");
      rollbar.error(error);
      Vue.$toast.error(message, { message, position: "top-right" });
    }
  } finally {
    sending.value = false;
  }
};

const authorizeWith3ds = async () => {
  const paymentIntent = await createPaymentIntent();
  await creditCardForm.value.authorizeWith3ds(paymentIntent);
  store.payment_method = "card";
  store.payment_intent_id = paymentIntent.id;
};

const onPayPalSuccess = (paymentIntent) => {
  store.payment_method = "paypal_express_checkout";
  store.payment_intent_id = paymentIntent.id;
  return confirmAndPay();
};

const onPaypalError = (error) => {
  console.error(error);
  const message = t("payment.adding_payment_method_failed");
  Vue.$toast.error(message, { message, position: "top-right" });
};

const createPaymentIntent = () => {
  return new PaymentIntentsService().create({
    amount: 0,
    currency_code: "USD",
  });
};

const togglePaymentMethod = (method: PaymentMethodType) => {
  if (paymentMethod.value === method) {
    paymentMethod.value = null;
  } else {
    paymentMethod.value = method;
  }
};

const createSubscription = async () => {
  const service = new SubscriptionsService();
  await service.create(
    {
      plan_price_id: plan.value.price_id,
      payment_intent_id: store.payment_intent_id,
      sign_up_code: store.sign_up_code,
    },
    {
      authenticationToken: store.authentication_token,
    }
  );
};

if (!store.authentication_token) {
  router.replace({ name: "SignupChoosePlan" });
}
</script>
