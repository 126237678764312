import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import ImageNodeView from "@/editor/extensions/image/image-node-view.vue";
import MediaFile from "@/model/media-file";
import { createImageDropHandler } from "@/editor/extensions/image/image-drop-handler";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    image: {
      addImage: (mediaFile: MediaFile) => ReturnType;
      updateImage: (mediaFile: MediaFile) => ReturnType;
    };
  }
}

const ImageNode = Node.create({
  name: "image",

  inline: true,

  group: "inline",

  draggable: true,

  addAttributes() {
    return {
      blob: {},
      image_width: { default: null },
    };
  },

  addCommands() {
    return {
      addImage:
        ({ file: { key, filename, content_type, metadata } }: MediaFile) =>
        ({ chain }) => {
          return chain()
            .insertContent({
              type: this.name,
              attrs: {
                blob: { key, filename, content_type, metadata },
              },
            })
            .run();
        },
      updateImage:
        ({ file: { key, filename, content_type, metadata } }: MediaFile) =>
        ({ commands }) => {
          return commands.updateAttributes(this.name, {
            blob: { key, filename, content_type, metadata },
          });
        },
    };
  },

  addProseMirrorPlugins() {
    if (this.editor.isEditable) {
      return [createImageDropHandler(this.editor)];
    } else {
      return [];
    }
  },

  // For some reason TipTap needs parseHTML and renderHTML to be present even while
  // our content is in json and rendering is done via vue...
  parseHTML() {
    return [
      {
        tag: "image-node",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["image-node", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return VueNodeViewRenderer(ImageNodeView);
  },
});

export default ImageNode;
