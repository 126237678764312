<template>
  <page>
    <div class="invitation-page-wrapper">
      <div v-if="invitation_invalid" class="invitation-page">
        <h1 class="mt-2">{{ $t("invitation.invitation_invalid") }}</h1>
      </div>
      <div v-if="invitation" class="invitation-page">
        <h1 class="mt-2">{{ $t("invitation.welcome_to_osgenic") }}</h1>
        <form @submit.prevent="onSubmit">
          <card>
            <div class="flex flex-row flex-center">
              <profile-photo ref="profilePhotoInput" :upload-on-change="false"></profile-photo>
            </div>
            <div v-if="error" class="form-error">{{ error }}</div>
            <form-field :label="$t('attributes.email')" :required="true" :error="$v.email.$error">
              <input v-model="email" type="email" />
            </form-field>
            <form-field :label="$t('attributes.name')" :required="true" :error="$v.name.$error">
              <input v-model="name" type="text" />
            </form-field>
            <form-field v-if="invitation.role_type == 'resident'" :label="$t('attributes.speciality')" :required="true">
              <speciality-select v-model="speciality_id" :error="$v.speciality_id.$error"></speciality-select>
            </form-field>
            <password-checker ref="new_password_form"></password-checker>
            <input type="hidden" name="token" :value="$route.params.token" />
            <div class="actions">
              <button class="btn-primary" :disabled="!isValid" @click.prevent="onSubmit">
                {{ $t("invitation.create_account") }}
              </button>
              <button class="btn-secondary" @click="onCancel">{{ $t("actions.cancel") }}</button>
            </div>
            <div class="eula_tos_links">
              <router-link class="nav-link" to="/eula" target="_blank">
                <div class="text-xs text-text-variant">{{ $t("pages.terms_of_service") }}</div>
              </router-link>
              <router-link class="nav-link" to="/privacy_statement" target="_blank">
                <div class="text-xs text-text-variant">{{ $t("pages.privacy_policy") }}</div>
              </router-link>
            </div>
          </card>
        </form>
      </div>
    </div>
  </page>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import Users from "@/services/users";
import Invitation from "@/model/invitation";
import Invitations from "@/services/invitations";
import { Validations } from "vuelidate-property-decorators";
import { required, helpers } from "vuelidate/lib/validators";
import { showDialog } from "@/utils/dialogs";
import LoadingIndicatorDialog from "@/components/loading-indicator-dialog.vue";
import PasswordChecker from "@/components/form/password-checker.vue";
import { login } from "@/actions";
import { Prop, Ref } from "vue-property-decorator";
import { UnprocessableEntityError } from "@/api/api-error";
import ProfilePhoto from "@/components/profile-photo/profile-photo.vue";
import Attachment from "@/model/attachment";
import { updateProfile } from "@/actions";
import ProfilePreferencesModal from "@/components/profile/profile-preferences-modal.vue";

@Component({})
export default class AddUserFromTokenPage extends Vue {
  @Ref("new_password_form")
  newPasswordForm: PasswordChecker;

  @Ref()
  profilePhotoInput: ProfilePhoto;

  @Prop()
  token: string;

  email = "";
  name = null;
  error_message = "";
  speciality_id = null;

  invitation: Invitation = null;
  invitation_invalid: Boolean = false;

  error: string = null;

  @Validations()
  validations = {
    name: { required },
    email: { required },
    speciality_id: {
      required: function (value) {
        if (this.invitation.role_type == "resident") {
          return helpers.req(value);
        } else {
          return true;
        }
      },
    },
  };

  get userService() {
    return new Users();
  }

  get invitationService() {
    return new Invitations();
  }

  get isValid() {
    return this.token != "";
  }

  mounted() {
    this.invitationService
      .getInvitation(this.token)
      .then((invitation) => {
        this.invitation = invitation;
        this.email = this.invitation.email;
      })
      .catch(() => {
        this.invitation_invalid = true;
      });
  }

  async login(email: String, password: String) {
    return login({
      email: email,
      password: password,
    });
  }

  redirect() {
    this.$router.push({ path: "/" });
    showDialog(ProfilePreferencesModal);
  }

  async onSubmit() {
    this.error = null;
    this.$v.$touch();
    this.newPasswordForm.$v.$touch();

    if (!this.$v.$error && !this.newPasswordForm.$v.$error) {
      return showDialog<boolean>(LoadingIndicatorDialog, { action: this.createUserAndLogin() });
    }
  }

  async createUserAndLogin() {
    try {
      await this.userService.create({
        email: this.email,
        name: this.name,
        password: this.newPasswordForm.newPassword,
        password_confirmation: this.newPasswordForm.confirmNewPassword,
        token: this.token,
        speciality_id: this.speciality_id,
      });
      await this.login(this.email, this.newPasswordForm.newPassword);

      // Save profile picture after login if set (saving needs authed user)
      if (this.profilePhotoInput.tempDataURL) {
        let attachment: Attachment = await this.profilePhotoInput.saveProfilePicture();
        await updateProfile({
          profile_picture: attachment,
        });
      }

      this.redirect();
    } catch (error) {
      if (error instanceof UnprocessableEntityError) {
        this.error = error.message;
      } else {
        this.error = "Unexpected error while creating user. Please contact customer service.";
      }
    }
  }

  onCancel() {
    this.$router.back();
  }
}
</script>

<style lang="scss" scoped>
.invitation-page-wrapper {
  overflow-y: scroll;
}

.invitation-page {
  max-width: 440px;
  margin: auto;

  h1 {
    text-align: center;
    margin-bottom: var(--spacing-lg);
  }
}

.inline-field {
  label {
    display: inline;
  }

  label:not(:first-child) {
    padding-left: 1em;
  }

  input {
    display: inline;
    width: 10px; // This shouldn't have effect, but it does
  }
}

.details {
  padding: 1em;
  margin-bottom: 1em;

  p:first-child {
    margin-top: 0;
  }

  width: max-content;
  border-radius: 5px;
}

input:read-only {
  color: var(--ui-color-text-variant);
}

.actions {
  margin-top: var(--spacing-lg);
  display: flex;
  justify-content: flex-end;

  > *:not(:last-child) {
    margin-right: var(--spacing-sm);
  }
}

.eula_tos_links {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: var(--spacing);
}

.form-error {
  color: var(--color-error);
  margin: var(--spacing) 0;
}
</style>
