<template>
  <div v-if="isAdmin || upcomingReleases.length > 0">
    <div class="flex items-center">
      <div class="text-label-strong text-text-primary flex-1" :class="{ 'mb-4': !isAdmin }">Upcoming Releases</div>
      <div v-if="isAdmin">
        <action-button icon="add" type="emphasis" :title="$t('actions.add')" @click="addUpcomingRelease" />
      </div>
    </div>
    <div class="flex flex-row-reverse justify-end gap-2 sm:gap-4">
      <upcoming-release-card
        v-for="release in upcomingReleases"
        :key="release.id"
        class="basis-1/3"
        :release="release"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCurrentUser } from "@/composables/session";
import { showDialog } from "@/utils/dialogs";
import UpcomingReleaseModal from "./upcoming-release-modal.vue";
import { useUpcomingReleases } from "@/api/upcoming-releases";
import UpcomingReleaseCard from "./upcoming-release-card.vue";
import { computed } from "vue";
import _ from "lodash";

const currentUser = useCurrentUser();

const isAdmin = computed(() => currentUser.value.isAdmin());

const { data } = useUpcomingReleases();

const upcomingReleases = computed(() => _.take(data.value, 3));

const addUpcomingRelease = () => {
  showDialog(UpcomingReleaseModal);
};
</script>
