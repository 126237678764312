<template>
  <div
    class="flex flex-col sm:flex-row sm:items-center gap-2 rounded-xs relative group"
    :class="isAdmin ? 'hover:bg-surface-min-contrast' : ''"
  >
    <div
      v-lazy:background-image="previewImage"
      class="w-full aspect-square sm:h-16 sm:w-16 bg-cover bg-center rounded-xs relative"
    >
      <div
        v-if="isAdmin"
        class="opacity-0 group-hover:sm:opacity-100 flex items-center justify-center absolute w-full h-full duration-300"
      >
        <ui-button-icon icon="edit" size="sm" @click="editRelease" />
      </div>
    </div>
    <div>
      <div class="text-label-small text-text-primary">{{ release.title }}</div>
      <div class="text-label-small text-text-variant hidden sm:block mt-1">{{ release.speciality.name }}</div>
    </div>
    <div v-if="isAdmin" class="absolute w-full sm:hidden flex justify-between p-2">
      <ui-button-icon icon="dismiss" variant="secondary" size="sm" @click="removeRelease(release)" />
      <ui-button-icon icon="edit" size="sm" @click="editRelease" />
    </div>
    <div v-if="isAdmin" class="opacity-0 group-hover:sm:opacity-100 absolute -top-2 -right-2 duration-300">
      <ui-button-icon icon="dismiss" size="xs" @click="removeRelease(release)" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDeleteUpcomingRelease } from "@/api/upcoming-releases";
import { useCurrentUser } from "@/composables/session";
import UpcomingRelease from "@/model/upcoming-release";
import { showDialog } from "@/utils/dialogs";
import { filePath } from "@/utils/paths";
import { computed } from "vue";
import UpcomingReleaseModal from "./upcoming-release-modal.vue";
import ConfirmationDialog from "@/components/confirmation-dialog.vue";
import { useI18n } from "vue-i18n-bridge";

const { t } = useI18n();
const props = defineProps<{ release: UpcomingRelease }>();

const currentUser = useCurrentUser();

const isAdmin = computed(() => currentUser.value.isAdmin());

const previewImage = computed(() => {
  return filePath(props.release.preview_image, "thumb");
});

const { mutate } = useDeleteUpcomingRelease(props.release.id);

const removeRelease = async (release: UpcomingRelease) => {
  const confirm = await showDialog(ConfirmationDialog, {
    title: t("confirmation.remove_item_confirmation"),
    okText: t("confirmation.yes_remove_it"),
    cancelText: t("confirmation.no_keep_it"),
  });
  if (confirm) {
    mutate(release.id);
  }
};

const editRelease = () => {
  return showDialog(UpcomingReleaseModal, { release: props.release });
};
</script>
